import React from "react"

import Index from "../components/index"
import SEO from "../components/other/seo"
import Container from "../components/layout/container";
import ProductThumbs from "../components/elements/product-thumbs";
import VideoRow from "../components/elements/video-row";
import {Link} from "gatsby";
import ProductOverviewBanner2Image from "../components/images/product-overview-banner-2";
import Video from "../components/elements/video";
import MicroSure from "../components/elements/micro-sure";

const ProductsPage = () => (
  <Index>
    <SEO title="Product Overview" />
    <Container id="product-overview-banner">
      <h1>Product Overview</h1>
    </Container>
    <Container>
      <div id="product-overview-banner-1">
        <div className="left"></div>
        <div className="right">
          <h2>Modern Day Protection</h2>
          <p>Unlike other sanitizing solutions that kill with toxic chemicals, leaving hands dry and surfaces unprotected shortly after application, <MicroSure /> persistent germ-destroying Product Line creates a long lasting nanoscopic crystal spiked layer where applied. By coating your hands, surfaces, and laundry with the <MicroSure /> product line, you are creating a defensive fortress that punctures and destroys on contact more than 99.99% of all harmful microorganisms (pathogens) that can make you sick.</p>
          <p>
            <br />
            <Link to="/order" className="button bordered inverse">Buy Now</Link>
          </p>
        </div>
      </div>
      <div id="product-overview-banner-2">
        <div className="left">
          <ProductOverviewBanner2Image />
        </div>
        <div className="right">
          <h2>Testing Proves Efficacy</h2>
          <p>Third party laboratories conducted multiple controlled tests on all formularies of the <MicroSure /> product line. These tests showed positive results in that <MicroSure />’s unique formula kills harmful microorganisms on contact by puncturing the cell wall and destroying the pathogen’s ability to replicate and infect.</p>
          <p>Lab Results Clinically proven to mechanically kill over 140 of the most common viruses, bacteria, fungi, and algae including SARS (Coronavirus Family), Influenza, H1N1, Poliovirus, mycobacterium tuberculosis, E. Coli, Salmonella, Staphylococcus, Steptococcus, and more.  </p>
        </div>
      </div>
      <ProductThumbs />
    </Container>
    <Container id="products-videos">
      <VideoRow>
        <div className="left">
          <Video title="Video" videoID="HtZNk60ntKw" />
        </div>
        <div className="right">
          <h3>Invisible Defense Barrier</h3>
          <p>Watch how <MicroSure /> product seals and protects by creating a defensive barrier, deadly to any harmful pathogens seeking to infect.</p>
        </div>
      </VideoRow>
      <VideoRow>
        <div className="left">
          <Video title="Video" videoID="HtZNk60ntKw" />
        </div>
        <div className="right">
          <h3>Nano-Sized Pathogens</h3>
          <p>Did you know that most harmful bacteria and virus cells are 10,000 times smaller than a grain of salt?</p>
        </div>
      </VideoRow>
      <VideoRow>
        <div className="left">
          <Video title="Video" videoID="HtZNk60ntKw" />
        </div>
        <div className="right">
          <h3>microSURE Killing Layer</h3>
          <p>Because each <MicroSure /> killing blade is 30 times smaller than the average harmful pathogen cell, it’s like tossing a balloon on a bed of needles- Ensured destruction to any virus or bacteria that makes contact with <MicroSure /> layer. </p>
        </div>
      </VideoRow>
    </Container>
  </Index>
)

export default ProductsPage
